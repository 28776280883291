import React from 'react'
import {
    Row, Col, Card, CardText, CardBody, CardTitle
} from 'reactstrap';
import './solucoes.css'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby';
import ScrollAnimation from 'react-animate-on-scroll';

export default class Solucoes extends React.Component {
    render() {
        return <StaticQuery
            query={graphql`
            query {
                apc: file(relativePath: { eq: "apc.png" }) {
                    childImageSharp {
                        fixed(width: 80, height: 80) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                apcon: file(relativePath: { eq: "apcon.png" }) {
                    childImageSharp {
                        fixed(width: 80, height: 80) {
                        ...GatsbyImageSharpFixed
                        }
                    }
                }
                solution: file(relativePath: { eq: "solution.png" }) {
                    childImageSharp {
                        fixed(width: 80, height: 80) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                sge: file(relativePath: { eq: "sge.png" }) {
                    childImageSharp {
                        fixed(width: 80, height: 80) {
                        ...GatsbyImageSharpFixed
                        }
                    }
                }
                integraapc: file(relativePath: { eq: "integraapc.jpg" }) {
                    childImageSharp {
                        fixed(width: 80, height: 80) {
                        ...GatsbyImageSharpFixed
                        }
                    }
                }
                sped: file(relativePath: { eq: "sped.jpg" }) {
                    childImageSharp {
                        fixed(width: 80, height: 80) {
                        ...GatsbyImageSharpFixed
                        }
                    }
                }
                caf: file(relativePath: { eq: "caf.jpg" }) {
                    childImageSharp {
                        fixed(width: 80, height: 80) {
                        ...GatsbyImageSharpFixed
                        }
                    }
                }
                softpdv: file(relativePath: { eq: "softpdv.png" }) {
                    childImageSharp {
                        fixed(width: 80, height: 80) {
                        ...GatsbyImageSharpFixed
                        }
                    }
                }
                mca: file(relativePath: { eq: "MCA.jpg" }) {
                    childImageSharp {
                        fixed(width: 80, height: 80) {
                        ...GatsbyImageSharpFixed
                        }
                    }
                }
                softtef: file(relativePath: { eq: "softtef.jpg" }) {
                    childImageSharp {
                        fixed(width: 80, height: 80) {
                        ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
            render={data => {
                const items = [
                    {
                        id: 'cardApcon',
                        imgSrc: data.apcon.childImageSharp.fixed,
                        title: 'APCON',
                        description: 'Frente de Caixa ágil e de fácil operação para o seu posto de combustível',
                    },
                    {
                        id: 'cardAPC',
                        imgSrc: data.apc.childImageSharp.fixed,
                        title: 'APC',
                        description: 'Sistema de retaguarda ERP completo para a gestão eficaz do seu posto, rede ou loja',
                    },
                    {
                        id: 'cardSOLUTION',
                        imgSrc: data.solution.childImageSharp.fixed,
                        title: 'SOLUTION',
                        description: 'O sistema amigável para a sua loja de conveniência vender mais',
                    },
                    {
                        id: 'cardSGE',
                        imgSrc: data.sge.childImageSharp.fixed,
                        title: 'SGE',
                        description: 'Sistema de BI. Relatórios customizados, avaliação de dados rapidamente para tomada de decisão.',
                    },
                    {
                        id: 'cardSPED',
                        imgSrc: data.sped.childImageSharp.fixed,
                        title: 'SPI SPED',
                        description: 'Geração do arquivo SPED Sistema de Processamento Escrituração Digital',
                    },
                    // {
                    //     id: 'cardSoftPDVApp',
                    //     imgSrc: data.softpdv.childImageSharp.fixed,
                    //     title: 'SOFT-PDV APP',
                    //     description: 'Aplicativo para agilizar ainda mais a pista do seu posto. NFC-e diretamente no veículo do seu cliente.',
                    // },
                    // {
                    //     id: 'cardSoftPDV',
                    //     imgSrc: data.softpdv.childImageSharp.fixed,
                    //     title: 'SOFT-PDV',
                    //     description: 'Sistema leve e ágil para a pista do seu posto de combustível.',
                    // },
                    {
                        id: 'cardIntegraAPC',
                        imgSrc: data.integraapc.childImageSharp.fixed,
                        title: 'INTEGRA APC',
                        description: 'Sistema para replicação de dados para rede de postos de combustíveis. Gerencie sua rede através de um escritório central.',
                    },
                    {
                        id: 'cardSoftTEF',
                        imgSrc: data.softtef.childImageSharp.fixed,
                        title: 'SOFT-TEF',
                        description: 'TEF Dedicado. Tenha mais segurança e praticidade com suas vendas de cartão integradas ao sistema.',
                    },
                    {
                        id: 'cardCAF',
                        imgSrc: data.caf.childImageSharp.fixed,
                        title: 'CAF',
                        description: 'Sistema voltado a estabelecimentos que possuem abastecimento próprio e necessitam do controle de sua frota.',
                    },
                    {
                        id: 'cardMCA',
                        imgSrc: data.mca.childImageSharp.fixed,
                        title: 'MCA',
                        description: 'Concentrador de abastecimentos. Desenvolvido pela Softplus é totalmente integrado aos nossos software, garantindo a mais alta confiabilidade e segurança para seu posto.',
                    },
                    {
                        id: 'cardMaxID',
                        imgSrc: data.mca.childImageSharp.fixed,
                        title: 'MaxID',
                        description: 'Identificador de frentistas com tecnologia RFID. Mais controle para seus abastecimentos.',
                    },
                ];

                const cards = items.map((item) => {
                    return <Col key={item.id}>
                        <ScrollAnimation animateOnce={true} offset={100} animateIn="fadeInLeft">
                            <Card className="cardProduto text-center" onClick={item.onClick} id={item.id}>
                                <Img className="mx-auto" fixed={item.imgSrc} alt={item.title} />
                                <CardBody>
                                    <CardTitle>{item.title}</CardTitle>
                                    <CardText>{item.description}</CardText>
                                </CardBody>
                            </Card>
                        </ScrollAnimation>
                    </Col>
                });

                return (
                    <section className="page-section" id="solucoes">
                        <div className="section-header">
                            <h1>SOLUÇÕES</h1>
                        </div>
                        <div className="sp-container">
                            <Row className="mx-auto">
                                {cards}
                            </Row>
                        </div>
                    </section>
                );
            }}
        />
    }
}