import React from 'react'
import './sobre.css'
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import ScrollAnimation from 'react-animate-on-scroll';
import Representantes from '../components/representantes';
import Video from './video';

export default class Sobre extends React.Component {
    render() {
        return <StaticQuery
            query={graphql`
            query {
                amamos: file(relativePath: { eq: "Amamos.png" }) {
                    childImageSharp {
                        fixed(width: 200, height: 180) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                clienteFirst: file(relativePath: { eq: "ClienteFirst.png" }) {
                    childImageSharp {
                        fixed(width: 200, height: 180) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                pioneiros: file(relativePath: { eq: "Pioneiros.png" }) {
                    childImageSharp {
                        fixed(width: 200, height: 180) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                qualificados: file(relativePath: { eq: "Qualificados.png" }) {
                    childImageSharp {
                        fixed(width: 200, height: 180) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
            render={data => {
                return (
                    <section className="page-section" id="sobre">
                        <div className="section-header">
                            <h1>SOBRE NÓS</h1>
                        </div>
                        <div className="content">
                            <div className="sp-banner sp-dark">
                                <div className="sp-container">
                                    <h2 className="text-center">Muitos anos de dedicação a disposição do seu posto de combustível</h2>
                                    <p className="text-center">
                                        Especializada em sistemas e automação para postos de combustíveis, desde 1989, atua no mercado nacional focada no desenvolvimento de soluções para o controle do seu posto, buscando sempre a SATISFAÇÃO de seus clientes e a qualificação de seus colaboradores.
                    </p>
                                    <Video />
                                </div>
                            </div>

                            <div id="SobreValores" className="sp-container">
                                <ScrollAnimation animateOnce={true} offset={100} animateIn="fadeInLeft">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-6">
                                            <div className="textblock">
                                                <p className="text-right"><strong>PIONEIROS E EXPERIENTES</strong></p>
                                                <p className="text-right">Uma das primeiras empresas no Brasil a desenvolver soluções de automação para postos de combustíveis. Temos o know-how que você precisa para ficar tranquilo quanto ao controle do seu negócio. Aprendemos cada vez mais ao longo dos 29 anos, sempre buscando a melhoria contínua.</p>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-6">
                                            <Img fixed={data.pioneiros.childImageSharp.fixed} alt="Somos Pioneiros" />
                                        </div>
                                    </div>
                                </ScrollAnimation>
                                <ScrollAnimation animateOnce={true} offset={100} animateIn="fadeInRight">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-6">
                                            <Img className="float-right" fixed={data.amamos.childImageSharp.fixed} alt="Ama o que faz" />
                                        </div>
                                        <div className="col-xs-12 col-sm-6">
                                            <div className="textblock">
                                                <p className="text-left"><strong>A GENTE AMA O QUE FAZ</strong></p>
                                                <p className="text-left">Abastecidos pelo amor ao que fazemos, somos inquietos e não cansamos de estudar e pesquisar o que há de mais tecnológico para desenvolver e apresentar soluções modernas para a sua empresa. Acreditamos que os detalhes fazem a diferença, e esse cuidado você irá encontrar nas nossas soluções.</p>
                                            </div>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                                <ScrollAnimation animateOnce={true} offset={100} animateIn="fadeInLeft">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-6">
                                            <div className="textblock">
                                                <p className="text-right"><strong>VALORIZAMOS O CLIENTE</strong></p>
                                                <p className="text-right">Para nós o cliente está SEMPRE em primeiro lugar. Agimos com respeito, honestidade e ética, evoluindo com um trabalho digno. Sabemos ouvir as opiniões dos usuários e valorizamos as sugestões. Buscamos o crescimento dos nossos sistemas JUNTO a todos envolvidos.</p>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-6">
                                            <Img fixed={data.clienteFirst.childImageSharp.fixed} alt="Valorizamos o cliente" />
                                        </div>
                                    </div>
                                </ScrollAnimation>
                                <ScrollAnimation animateOnce={true} offset={100} animateIn="fadeInRight">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-6">
                                            <Img className="float-right" fixed={data.qualificados.childImageSharp.fixed} alt="Qualificados" />
                                        </div>
                                        <div className="col-xs-12 col-sm-6">
                                            <div className="textblock">
                                                <p className="text-left"><strong>TEMOS PROFISSIONAIS QUALIFICADOS</strong></p>
                                                <p className="text-left">Somos feitos de pessoas que pensam grande e gostam de desafios. Possuímos em nossa equipe um conjunto de profissionais com conhecimento que, quando se juntam num objetivo, o resultado aparece!</p>
                                                <p className="text-left">Pessoas + tecnologia + conhecimento = resultado para o cliente.</p>
                                            </div>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>

                        <div className="sp-banner sp-red">
                            <ScrollAnimation animateOnce={true} offset={100} animateIn="zoomIn">
                                <h1 className="text-center">O nosso negócio é fazer você ter o controle do seu posto.</h1>
                            </ScrollAnimation>
                        </div>
                        <Representantes />
                    </section>
                );
            }}
        />
    }
}