import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'reactstrap';
import Img from 'gatsby-image';
import ScrollAnimation from 'react-animate-on-scroll';
import './representantes.css';

const Representantes = () => (
    <StaticQuery
        query={graphql`
                query {
                    mapa: file(relativePath: { eq: "mapa_brasil.png" }) {
                        childImageSharp {
                            fixed(height: 400, width: 410) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            `}
        render={data => {
            return <div className="sp-dark" id="representantes">
                <Container>
                    <Row>
                        <Col className="col-img">
                            <ScrollAnimation animateOnce={true} offset={100} animateIn="rollIn">
                                <Img fixed={data.mapa.childImageSharp.fixed} />
                            </ScrollAnimation>
                        </Col>
                        <Col className="col-desc">
                            <ScrollAnimation animateOnce={true} offset={100} animateIn="lightSpeedIn">
                                <h2>ATUAMOS EM TODO O BRASIL</h2>
                                <br />
                                <p>Nosso atendimento no país se dá tanto através da matriz quanto com atendimento de representantes qualificados.</p>
                                <p>Entre em contato e saiba onde tem um representante mais perto da sua região.</p>
                                <p>Caso tenha interesse em fazer parte do nosso time de representantes, entre em contato com nosso setor comercial. A Softplus está sempre em busca de novas parcerias.</p>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </div>
        }}
    />
);

export default Representantes;