import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Img from 'gatsby-image';
import './video.css';

export default class Video extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {
        return <StaticQuery
            query={graphql`
                query {
                    capaVideo: file(relativePath: { eq: "capa_video2.png" }) {
                        childImageSharp {
                            fluid(maxHeight: 321, maxWidth: 535) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            `}
            render={data => {
                return (
                    <div id="video" className="text-center m-3">
                        <span onClick={this.toggle}>
                            <Img className="img-video mx-auto" onClick={this.toggle} style={{ maxWidth: '535px', maxHeight: '321px' }} fluid={data.capaVideo.childImageSharp.fluid} />
                        </span>
                        <Modal className='modal-video' isOpen={this.state.modal} toggle={this.toggle}>
                            <ModalHeader>
                                <button className='close' onClick={this.toggle} aria-label="fechar">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </ModalHeader>
                            <ModalBody>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe
                                        title="institucional"
                                        width='100%'
                                        height='100%'
                                        className="embed-responsive-item"
                                        src="https://www.youtube.com/embed/0JXIdqtMUls?rel=0&amp;showinfo=0&amp;modestbranding=1&amp;autoplay=1"
                                        id="video"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
                                        allowFullScreen
                                        allowscriptaccess="always"
                                    >
                                    </iframe>
                                </div>

                            </ModalBody>
                        </Modal>
                    </div>
                );
            }}
        />;
    }
}