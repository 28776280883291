import React from 'react';
import { Row, Col, Button, Form, FormFeedback, FormGroup, Label, Input } from 'reactstrap';

const Contato = () => (
    <section className="page-section" id="contato">
        <div className="sp-container">
            <div className="section-header">
                <h1>Entre em contato conosco</h1>
                <p>Estamos ansiosos pelo seu contato, preencha o formulário abaixo.</p>
            </div>
            <ContatoForm />
        </div>

    </section>
);

export default Contato;


class ContatoForm extends React.Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.state = {
            nome: '',
            telefone: '',
            email: '',
            nomeEmpresa: '',
            mensagem: '',
            invalidNome: false,
            invalidTelefone: false,
            invalidEmail: false,
            disableSubmit: false,
        };
    }

    handleFieldChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
    }

    submit() {
        this.setState({
            disableSubmit: true
        });

        var invalidNome, invalidTelefone, invalidEmail = false;
        if (this.state.nome === '') {
            invalidNome = true;
        }
        if (this.state.telefone === '') {
            invalidTelefone = true;
        }
        var reEmail =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.state.email === '' || reEmail.test(this.state.email) === false) {
            invalidEmail = true;
        }
        this.setState({
            invalidNome: invalidNome,
            invalidTelefone: invalidTelefone,
            invalidEmail: invalidEmail
        });

        if (!invalidNome && !invalidTelefone && !invalidEmail) {
            fetch('https://spi-infra.azurewebsites.net/api/SoftPdv/NovoContato',
                {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "origem": "Site",
                        "razao": this.state.nomeEmpresa,
                        "nome": this.state.nome,
                        "email": this.state.email,
                        "telefone": this.state.telefone,
                        "mensagem": this.state.mensagem
                    })
                }
            ).then(resp => {
                if (resp.status === 200) {
                    this.clear();
                    alert('Sua mensagem foi enviada com sucesso!');
                }
            }).catch(err => {
                this.setState({
                    disableSubmit: false
                });
                return err;
            });
        } else {
            this.setState({
                disableSubmit: false
            });
        }
    }

    clear() {
        this.setState({
            nome: '',
            telefone: '',
            email: '',
            nomeEmpresa: '',
            mensagem: '',
            invalidNome: false,
            invalidTelefone: false,
            invalidEmail: false,
            disableSubmit: false,
        });
    }

    render() {
        return (
            <Form>
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="inputNome">Nome*</Label>
                            <Input
                                type="text"
                                name="nome"
                                id="inputNome"
                                invalid={this.state.invalidNome}
                                onChange={this.handleFieldChange}
                                value={this.state.nome}
                            />
                            {this.state.invalidNome === true ? <FormFeedback>Informe seu nome.</FormFeedback> : null}
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="inputTelefone">Telefone*</Label>
                            <Input
                                type="number"
                                name="telefone"
                                id="inputTelefone"
                                invalid={this.state.invalidTelefone}
                                onChange={this.handleFieldChange}
                                value={this.state.telefone}
                            />
                            {this.state.invalidTelefone === true ? <FormFeedback>Informe o telefone.</FormFeedback> : null}
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="inputEmail">E-mail*</Label>
                            <Input
                                type="email"
                                name="email"
                                id="inputEmail"
                                invalid={this.state.invalidEmail}
                                onChange={this.handleFieldChange}
                                value={this.state.email}
                            />
                            {this.state.invalidEmail === true ? <FormFeedback>Informe um e-mail válido.</FormFeedback> : null}
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="inputEmpresa">Nome da empresa</Label>
                            <Input
                                type="text"
                                name="nomeEmpresa"
                                id="inputEmpresa"
                                onChange={this.handleFieldChange}
                                value={this.state.nomeEmpresa}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup>
                    <Label for="inputMensagem">Mensagem</Label>
                    <Input
                        type="textarea"
                        name="mensagem"
                        id="inputMensagem"
                        onChange={this.handleFieldChange}
                        value={this.state.mensagem}
                    />
                </FormGroup>
                <Button
                    color="danger"
                    size="lg"
                    block
                    disabled={this.state.disableSubmit}
                    onClick={this.submit}
                >Enviar</Button>
            </Form>
        );
    }
}