import React from 'react';
import { Button } from 'reactstrap';
import { StaticQuery, graphql } from 'gatsby';
import './area_cliente.css';

const AreaCliente = () => (
    <StaticQuery
        query={graphql`
                query {
                    areaCliente: file(relativePath: { eq: "area_cliente.jpg" }) {
                        childImageSharp {
                            fluid(maxHeight: 300, maxWidth: 1980) {
                                ...GatsbyImageSharpFluid
                                src
                            }
                        }
                    }
                }
            `}
        render={data => {
            return <section className="page-section" id="areacliente">
                <div style={{ minHeight: '300px', background: `url(${data.areaCliente.childImageSharp.fluid.src})` }}>
                    <div className="section-header">
                        <h1>Área do Cliente</h1>
                        <p>Tenha acesso ao portal de manuais, documentos legislativos e mais.</p>
                    </div>
                    <div className="panelButtons">
                        <Button className="rounded-0" color="info" size="lg" onClick={() => window.open('http://www.spi.com.br/news/contatoSpi/spidocs.html')}>ACESSAR</Button>
                    </div>
                </div>
            </section>
        }}
    />
);

export default AreaCliente;