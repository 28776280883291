import React from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Jumbotron,
  Button
} from 'reactstrap';
import './home.css';
import * as Util from '../util';
import Img from 'gatsby-image';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      showingModal: true
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.onEntering = this.onEntering.bind(this);
    this.scrollToSolution = this.scrollToSolution.bind(this);
    this.items = [
      {
        id: 1,
        src: this.props.images[0].fluid,
        altText: 'Slide 1',
        caption: 'Slide 1',
        shadow: true,
        content: (
          <Jumbotron>
            <h1 className="display-3">Automação e Sistemas para postos de combustíveis</h1>
            <p className="lead">Deixe tudo com a gente! Automação e sistema para posto de combustível em um só lugar.</p>
            <hr className="my-2" />
            <p>Já utiliza outro concentrador? Nossos sistemas são integrados com os principais concentradores do mercado.</p>
            <p className="lead">
              <Button color="danger" onClick={this.scrollToSolution}>Confira nossas soluções</Button>
            </p>
          </Jumbotron>
        ),
      },
      {
        id: 2,
        src: this.props.images[1].fluid,
        altText: 'Slide 2',
        caption: 'Slide 2',
        shadow: true,
        content: (
          <Jumbotron>
            <h1 className="display-3">Nós temos a solução fiscal que seu posto precisa</h1>
            <p className="lead">NFC-e, NF-e, PAF-ECF, MDF-e, NFS-e, SPED</p>
            <hr className="my-2" />
            <p>Nossos sistemas emitem e controlam os documentos fiscais que você precisa.</p>
            <p className="lead">
              <Button color="danger" onClick={this.scrollToSolution}>Confira nossas soluções</Button>
            </p>
          </Jumbotron>
        ),
      }
    ];

    this.slides = this.items.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          onEntering={this.onEntering}
          key={item.id}
        >
          <div>
            {item.content}
            <Img
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
              }}
              fluid={item.src} />
            {item.shadow ? <div className="sp-opacity"></div> : null}
          </div>

        </CarouselItem>
      );
    });
  }

  scrollToSolution() {
    var target = document.querySelector("#solucoes");
    Util.ScrollToTarget(target);
  }

  onToggle() {
    this.setState({ showingModal: false });
  }

  onEntering({ clientHeight }) {
    this.setState({ carouselHeight: clientHeight }, () => {
      var inner = document.querySelector('.carousel-inner');

      var innerHeight = parseInt(inner.style.height.replace('px', '')) || 0;
      if (innerHeight < clientHeight) {
        inner.style.height = clientHeight + 'px';
      }
    });
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    return (
      <section className="page-section" id="home">
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
          pause={"hover"}
        >
          <CarouselIndicators items={this.items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
          {this.slides}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
        </Carousel>

        <Img
          style={{
            display: 'none'
          }}
          fixed={this.props.images[2].fixed}
        />

        <Modal isOpen={typeof (document) !== 'undefined' ? this.state.showingModal === true : false} toggle={() => this.onToggle()} className="modal-aviso">
          <ModalHeader toggle={() => this.onToggle()}>COMUNICADO IMPORTANTE</ModalHeader>
          <ModalBody>
            <p>Em razão das enchentes no RS e do re-estabelecimento das atividades de vários clientes e fornecedores e
              das instabilidades da SEFAZ/RS, estamos com elevado fluxo de atendimentos.
              Nosso WhatsApp (51) 3041-0115 é o melhor meio de contato.
              Se você já entrou em contato por ele, não se preocupe que iremos retornar o mais breve possível.
            </p>
            <p>Por favor, pedimos que, ao solicitar o setor de suporte, expliquem o motivo do contato chamando apenas uma vez, pois nosso atendimento é por ordem de chamada, do mais antigo ao mais recente, então novas mensagens levam os chamados ao final da fila.</p>
            <p>Agradecemos a compreensão.</p>
          </ModalBody>
        </Modal>
      </section>
    );
  }
}