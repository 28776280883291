import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Menu from '../components/menu'
import './layout.css'

export default class Layout extends React.Component {
  render() {
    return <StaticQuery
      query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            siteUrl

          }
        }
      }
    `}
      render={data => {
        return <>
          <Helmet
            title={data.site.siteMetadata.title}
          >
            <html lang="pt-br" />
            {/* <base href="/v2/" target="_blank"></base> */}
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta http-equiv="cache-control" content="max-age=0" />
            <meta http-equiv="cache-control" content="no-store" />
            <meta http-equiv="expires" content="-1" />
            <meta http-equiv="expires" content="Tue, 01 Jan 1980 1:00:00 GMT" />
            <meta http-equiv="pragma" content="no-cache" />
            <meta name="description" content="A Softplus Informática é pioneira e especialista em automação e sistemas para postos de combustíveis." />
            <meta name="keywords" contet="sistema, posto, combustível, automação, postos, combustíveis" />
          </Helmet>
          <Menu location={this.props.location} />
          {this.props.children}
        </>
      }}
    />
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}
