import React from 'react'
import Layout from '../components/layout'
import Contato from '../components/contato'
import Sobre from '../components/sobre'
import Solucoes from '../components/solucoes';
import Footer from '../components/footer';
import Home from '../components/home';
import Destaques from '../components/destaques';
import AreaCliente from '../components/area_cliente';
import SyncActiveMenu from '../components/syncActiveMenu';
import { graphql } from 'gatsby';
import '../css/animate.min.css';
import '../css/bootstrap.min.css';
import '../css/fontawesome-all.min.css';
import './index.css';

export const query = graphql`
query {
    slide1: file(relativePath: { eq: "posto_combustivel_softplus.jpg" }) {
        childImageSharp {
            fluid(maxHeight: 1000, maxWidth: 1980) {
            ...GatsbyImageSharpFluid
            }
        }
    }
    slide2: file(relativePath: { eq: "sucesso_sistema_posto_combustivel.jpg" }) {
        childImageSharp {
            fluid(maxHeight: 1000, maxWidth: 1980) {
            ...GatsbyImageSharpFluid
            }
        }
    }
    comunicado: file(relativePath: { eq: "comunicado_covid.jpg" }) {
        childImageSharp {
            fixed(width: 821, height: 1117) {
                ...GatsbyImageSharpFixed
            }
        }
    }
}
`;


export default class IndexPage extends React.Component {
    componentDidMount() {

    }

    render() {
        return (
            <Layout>
                <main id="master" className="sp-offwhite">
                    <SyncActiveMenu />
                    <Home images={[this.props.data.slide1.childImageSharp, this.props.data.slide2.childImageSharp, this.props.data.comunicado.childImageSharp]} />
                    <Solucoes />
                    <Destaques />
                    <Sobre />
                    <AreaCliente />
                    <Contato />
                    <Footer />
                </main>
            </Layout >
        );
    }
}