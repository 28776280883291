import React from 'react'

export default class SyncActiveMenu extends React.Component {
    componentDidMount() {
        window.addEventListener('scroll', () => {
            var fixedMenuHeight = document.querySelector('#menu').clientHeight;
            var offset = 10;

            document.querySelectorAll(".page-section").forEach(section => {
                if (Math.round(section.offsetTop - offset) <= (window.scrollY + fixedMenuHeight)) {
                    var activeElement = document.querySelector('.nav-item.active');
                    if (activeElement) {
                        activeElement.classList.remove('active');
                    }
                    var sectionElement = document.querySelector('.nav-item a[href="#' + section.id + '"]');
                    if (sectionElement) {
                        sectionElement.parentElement.classList.add('active');
                    }
                }
            });
        });
    }

    render() {
        return null;
    }
}