import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import './footer.css'

const Footer = () => (
  <footer className="sp-dark">
    <Container>
      <Row>
        <Col>
          <div className="panelIcon">
            <a target="_blank" rel="noopener noreferrer" aria-labelledby="endereco" href="https://www.google.com.sg/maps/place/Av. General Flores da Cunha, 903 - Centro, Cachoeirinha - RS">
              <i className="fas fa-map-marker-alt"></i>
            </a>
          </div>
          <address id="endereco">Av. General Flores da Cunha, 903 - Centro, Cachoeirinha - RS</address>
        </Col>
        <Col>
          <div className="panelIcon">
            <a href="mailto:comercial@spi.com.br" aria-labelledby="email" rel="noopener noreferrer">
              <i className="fas fa-envelope"></i>
            </a>
          </div>
          <div id="email">comercial@spi.com.br</div>
        </Col>
        <Col>
          <div className="panelIcon">
            <a href="tel: +55 (51) 3041-0131" aria-labelledby="telefone" rel="noopener noreferrer">
              <i className="fas fa-phone"></i>
            </a>
          </div>
          <div id="telefone">(51) 3041-0131</div>
        </Col>
        <Col>
          <div className="panelIcon">
            <a href="https://api.whatsapp.com/send?phone=555130410115" aria-labelledby="whatsapp" rel="noopener noreferrer">
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
          <div id="whatsapp">(51) 3041-0115</div>
        </Col>
      </Row>
    </Container>
    <Container id="copyrights">
      <Row>
        <Col>
          <div>© 2024 Direitos Reservados - Softplus Informática Ltda</div>
          <br />
          <div>CNPJ 93.632.370/0001-22</div>
          <br />
          <h6>AUTOMAÇÃO E SISTEMAS PARA POSTOS DE COMBUSTÍVEIS</h6>
        </Col>
      </Row>
    </Container>
  </footer>
)

export default Footer;