import React from 'react';
import {
    Container, Row, Col,
} from 'reactstrap';
import './destaques.css'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby';


export default class Destaques extends React.Component {
    render() {
        return <StaticQuery
            query={graphql`
        query {
            multi_empresas: file(relativePath: { eq: "01_pert_network_diagram.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            permissoes: file(relativePath: { eq: "02_lock.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            liberacao: file(relativePath: { eq: "03_allow_all.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            biometria: file(relativePath: { eq: "04_fingerprint_scanner.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            id_aba: file(relativePath: { eq: "05_card.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            caixa: file(relativePath: { eq: "06_cashbox.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            importacao_nota: file(relativePath: { eq: "07_import_file.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            sugestao: file(relativePath: { eq: "tank_truck.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            lmc: file(relativePath: { eq: "08_book.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            cpr: file(relativePath: { eq: "09_accounting.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            anexos: file(relativePath: { eq: "10_attachment.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            faturas: file(relativePath: { eq: "11_collection_account.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            boletos: file(relativePath: { eq: "12_invoice.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            extrato: file(relativePath: { eq: "13_bank.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            fidelidade: file(relativePath: { eq: "14_credit.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            limitecredito: file(relativePath: { eq: "15_mortgage.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            promocoes: file(relativePath: { eq: "16_market_segmentation.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            precos_diferenciados: file(relativePath: { eq: "17_price_tag.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            dre: file(relativePath: { eq: "18_sales.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            cartoes: file(relativePath: { eq: "19_credit_cards.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            cheques: file(relativePath: { eq: "20_check.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            ordemfrete: file(relativePath: { eq: "21_dispatch_order.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            estoque: file(relativePath: { eq: "22_tank_wagon.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            etiquetas: file(relativePath: { eq: "23_barcode.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            relatorios: file(relativePath: { eq: "24_report.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            fiscais: file(relativePath: { eq: "certificate.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            facil: file(relativePath: { eq: "hand_thumbsup.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            rapido: file(relativePath: { eq: "rocket.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            touchscreen: file(relativePath: { eq: "touchscreen.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            programacao: file(relativePath: { eq: "clock.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            vales: file(relativePath: { eq: "receipt.png" }) {
                childImageSharp {
                    fixed(width: 72, height: 72) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `}
            render={data => {
                const destaques = [
                    {
                        titulo: 'FÁCIL',
                        descricao: 'O PDV possui telas que facilitam a leitura e atalhos de fácil compreensão, com emissão da venda em duas teclas.',
                        imagem: data.facil.childImageSharp.fixed
                    },
                    {
                        titulo: 'NFC-e / NF-e / PAF-ECF / MDF-e / NFS-e',
                        descricao: 'Os sistemas são totalmente homologados e sempre atualizados com as últimas normas fiscais.',
                        imagem: data.fiscais.childImageSharp.fixed
                    },
                    {
                        titulo: 'TOUCH SCREEN',
                        descricao: 'Permite a utilização em monitores touch screen, com telas intuitivas e botões de fácil acesso para a venda.',
                        imagem: data.touchscreen.childImageSharp.fixed
                    },
                    {
                        titulo: 'PROGRAMAÇÃO DE PREÇOS',
                        descricao: 'Agende um dia/horário para a troca de preços nas bombas e o sistema irá efetuar a troca automaticamente.',
                        imagem: data.programacao.childImageSharp.fixed
                    },
                    {
                        titulo: 'VALES',
                        descricao: 'O sistema APCON possui recursos para emissão de vales para clientes e funcionários, além de emissão de vale troco.',
                        imagem: data.vales.childImageSharp.fixed
                    },
                    {
                        titulo: 'MULTI EMPRESAS',
                        descricao: 'Faça login ou defina a empresa selecionada no sistema, caso você tenha uma rede de postos integrada. Veja dados de todas as empresas da sua rede.',
                        imagem: data.multi_empresas.childImageSharp.fixed
                    },
                    {
                        titulo: 'SEGURANÇA COM CONTROLE DE PERMISSÕES',
                        descricao: 'Configure permissões de ações no sistema, personalize se o usuário pode executar determinada ação, consultar, incluir, excluir ou editar cadastros. Tudo pode ser configurado por grupo de usuário, usuário, telas e ações individuais no sistema.',
                        imagem: data.permissoes.childImageSharp.fixed
                    },
                    {
                        titulo: 'LIBERAÇÃO DE AÇÕES',
                        descricao: 'Ao ser bloqueado por uma ação que o usuário não possui permissão, o sistema solicita a liberação para que um usuário com permissão possa desbloquear e seguir com a execução. Essa liberação pode ser feita através de cartão, biometria ou senha.',
                        imagem: data.liberacao.childImageSharp.fixed
                    },
                    {
                        titulo: 'BIOMETRIA',
                        descricao: 'Tenha ainda mais segurança nas suas operações. Você pode utilizar a biometria para liberação de ações para usuários e identificação de clientes cadastrados, garantindo que o cliente informado está realmente comprando no posto.',
                        imagem: data.biometria.childImageSharp.fixed
                    },
                    {
                        titulo: 'ABASTECIMENTOS IDENTIFICADOS',
                        descricao: 'Tenha o controle dos abastecimentos que foram identificados utilizando Max-ID ou outro identificador. Controle a prestação de contas no caixa, sabendo exatamente quanto cada frentista deve pagar. Pague comissões corretamente para os frentistas, motivando-os para um melhor atendimento.',
                        imagem: data.id_aba.childImageSharp.fixed
                    },
                    {
                        titulo: 'TOTAL CONTROLE DO CAIXA',
                        descricao: 'Saiba tudo que entrou e saiu no caixa geral (diário) e no caixa de cada frentista. Emita relatórios personalizados das informações dos caixas, permitindo visualizar o que realmente importa para você. Veja os valores das prestações e de entradas de valores em dinheiro, cheques, cartões, ordens de frete (carta frete), tickets, vales, notas a prazo, etc.',
                        imagem: data.caixa.childImageSharp.fixed
                    },
                    {
                        titulo: 'MANIFESTAÇÃO E IMPORTAÇÃO DE NOTAS DE ENTRADA',
                        descricao: 'O sistema identifica as notas emitidas contra seu CNPJ para serem manifestadas e importadas. Vincule apenas uma vez os produtos do fornecedores com os seus produtos cadastrados, nas próximas importações com os mesmos produtos, o sistema irá conciliar automaticamente para você.',
                        imagem: data.importacao_nota.childImageSharp.fixed
                    },
                    {
                        titulo: 'SUGESTÃO DE COMPRA DE COMBUSTÍVEL',
                        descricao: 'Informe parâmetros para cálculo como data do pedido, data da entrega, semanas para cálculo da média e obtenha a quantidade sugerida para compra com base nas vendas e no estoque.',
                        imagem: data.sugestao.childImageSharp.fixed
                    },
                    {
                        titulo: 'LMC (LIVRO MOVIMENTAÇÃO DE COMBUSTÍVEIS)',
                        descricao: 'Gere o termo de abertura, encerramento e o LMC com base nas informações das suas vendas, compras, estoque e encerrantes das bombas. O sistema já preenche automaticamente os dados conforme a movimentação do dia.',
                        imagem: data.lmc.childImageSharp.fixed
                    },
                    {
                        titulo: 'CONTAS A RECEBER E A PAGAR',
                        descricao: 'Saiba quanto seu posto tem em valores a receber ou a pagar, quais clientes estão inadimplentes, quantas parcelas estão em atraso, determine valores percentuais de juros por tempo de atraso para cálculo automático. Com filtros, agrupamentos e ordenações fáceis veja os dados da forma como preferir. Na hora da quitação, negocie com seu cliente podendo conceder desconto e pagar em cartão ou cheque, tudo gerenciado pelo sistema APC.',
                        imagem: data.cpr.childImageSharp.fixed
                    },
                    {
                        titulo: 'ANEXOS',
                        descricao: 'Anexe documentos de qualquer tipo como imagens ou pdf de recibos, cheques, perdidos, notas ou o que julgar importante em qualquer cadastros do sistema.',
                        imagem: data.anexos.childImageSharp.fixed
                    },
                    {
                        titulo: 'FATURAS AUTOMÁTICAS',
                        descricao: 'Configure períodos de faturamento para o fechamento automático de faturas de seus clientes. Gere e envio o  boleto, notas e a fatura consolidada com todas compras do período diretamente para o e-mail de seus clientes.',
                        imagem: data.faturas.childImageSharp.fixed
                    },
                    {
                        titulo: 'EMISSÃO DE BOLETOS E REMESSA',
                        descricao: 'Emita o boleto das suas faturas e gere a remessa para transmissão ao banco. Importe o arquivo de retorno da cobrança do banco e seus boletos e contas a receber serão quitados automaticamente. O sistema está homologado com os principais bancos do país.',
                        imagem: data.boletos.childImageSharp.fixed
                    },
                    {
                        titulo: 'EXTRATO DE CONTAS',
                        descricao: 'Veja em detalhes o extrato das contas dos disponíveis, marque as contas como conciliadas para visualizar somente os registros conciliados com a conta bancária.',
                        imagem: data.extrato.childImageSharp.fixed
                    },
                    {
                        titulo: 'FIDELIDADE COM PONTUAÇÃO DE CLIENTES',
                        descricao: 'Configure pontos por produtos, quantidade mínima para pontuar, estabeleça o valor em R$ que vale cada ponto, faça a baixa de pontuação. Os pontos servem de forma de pagamento no momento da compra. Faça seu cliente ganhar benefícios e não trocar seu posto pelo concorrente.',
                        imagem: data.fidelidade.childImageSharp.fixed
                    },
                    {
                        titulo: 'LIMITE DE CRÉDITO PARA CLIENTES E VEÍCULOS',
                        descricao: 'Estabeleça um limite de crédito disponível para clientes ou veículos que estejam comprando com nota a prazo. Caso o cliente ultrapasse do limite, será bloqueado a venda a prazo para o mesmo, podendo o usuário liberar mediante autorização.',
                        imagem: data.limitecredito.childImageSharp.fixed
                    },
                    {
                        titulo: 'PROMOÇÕES',
                        descricao: 'Cadastre promoções com data de início e fim, condicionando quais produtos e sua quantidade que devem estar presentes no cupom para ganhar um desconto ou brinde. O sistema automaticamente identifica a presença destes itens e concede o benefício no final da venda.',
                        imagem: data.promocoes.childImageSharp.fixed
                    },
                    {
                        titulo: 'REGRAS DE PREÇOS',
                        descricao: 'Configure regras de preços em valores fixos, percentuais de desconto ou por valor de desconto, para grupos de clientes, clientes, categorias de clientes, produtos, formas de pagamento como cartão de crédito, débito, dinheiro. Os valores podem ser concedidos como desconto na venda.',
                        imagem: data.precos_diferenciados.childImageSharp.fixed
                    },
                    {
                        titulo: 'DRE - DEMONSTRATIVO DE RESULTADOS DO EXERCÍCIO',
                        descricao: 'Acompanhe seu DRE, visualizando as contas que mais houveram despesas ou receitas, visualize seus custos e o lucro final. Defina a forma de visualização: sintética, analítica por conta ou analítica lançamento por lançamento. O DRE pode ser visualizado por trimestre, obtendo assim uma fácil visualização para comparação entre os meses. Tenha diversas outras comparações anual, mensal, diária através do sistema SGE.',
                        imagem: data.dre.childImageSharp.fixed
                    },
                    {
                        titulo: 'CONTROLE DE CARTÕES',
                        descricao: 'Configure as taxas e dias para reembolso por operadora de cartão e o sistema irá dar baixa automaticamente para a conta bancária selecionada no dia do pagamento, separando a taxa do valor recebido. Você pode conferir os lançamentos de cartões quitados para realizar a conciliação com a conta bancária.',
                        imagem: data.cartoes.childImageSharp.fixed
                    },
                    {
                        titulo: 'CONTROLE DE CHEQUES',
                        descricao: 'Saiba os cheques a prazo e a vista recebidos, altere o cheque para devolvido, controle as quitações e valores a receber. Você ainda pode emitir cheques para fins de troco ou pagamentos.',
                        imagem: data.cheques.childImageSharp.fixed
                    },
                    {
                        titulo: 'ORDEM DE FRETE (CARTA FRETE)',
                        descricao: 'Receba ordens de frete como meio de pagamento e emita novas ordens com valores atualizados.',
                        imagem: data.ordemfrete.childImageSharp.fixed
                    },
                    {
                        titulo: 'CONTROLE DE ESTOQUE',
                        descricao: 'Saiba quanto você possui em estoque em quantidade ou em valor financeiro. Controle diferentes pontos de estoque. Faça inventário de contagem a qualquer horário do dia sem parar suas vendas, por grupo de produtos, subgrupo, produtos, como achar melhor. Importe dados de coletores eletrônicos como Lucas 9000, Autronic, etc.',
                        imagem: data.estoque.childImageSharp.fixed
                    },
                    {
                        titulo: 'ETIQUETAS DE PRODUTOS',
                        descricao: 'Imprima etiquetas de código de barras, preços e descrição de produtos para seus expositores com impressoras Argox.',
                        imagem: data.etiquetas.childImageSharp.fixed
                    },
                    {
                        titulo: 'RELATÓRIOS',
                        descricao: 'O sistema possui uma enorme variedade de relatórios com diversos tipos de filtros, agrupamentos, ordenações e comparativos de dados. Você ainda pode visualizar dados com o sistema SGE com uma tabela dinâmica para comparativo de diversas perspectivas e com uma interface com gráficos. Caso você ainda não tenha o dado da forma como precisa, disponibilizamos o menu relatórios genéricos, no qual arquivos com layout de relatórios podem ser criados por nossa equipe e importados para seu sistema. Os relatórios ainda permitem a exportação para Excel e PDF.',
                        imagem: data.relatorios.childImageSharp.fixed
                    },
                ];

                var columns = destaques.map((item, idx) => {
                    return (
                        <Col md={6} key={'col_' + idx}>
                            <Row>
                                <Col md={2}>
                                    <Img fixed={item.imagem} />
                                </Col>
                                <Col md={10}>
                                    <h6>{item.titulo}</h6>
                                    <span className="text-desc">{item.descricao}</span>
                                </Col>
                            </Row>
                        </Col>
                    );
                });

                return (
                    <section className="page-section" id="destaques">
                        <Container>
                            <div className="section-header">
                                <h1>DESTAQUES</h1>
                            </div>
                            <Row>
                                {columns}
                            </Row>
                        </Container>
                    </section>
                );
            }} />
    }
}