import React from 'react';
import './menu.css';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import * as Util from '../util';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';

export default class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.animateScrollTo = this.animateScrollTo.bind(this);
    this.state = {
      isOpen: false
    };
  }

  animateScrollTo(e) {
    e.preventDefault();

    var target = document.querySelector(e.target.getAttribute('href'));
    Util.ScrollToTarget(target);

    return false;
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return <StaticQuery
      query={graphql`
                query {
                    logo: file(relativePath: { eq: "logo.png" }) {
                        childImageSharp {
                            fixed(height: 64, width: 256) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            `}
      render={data => {
        return (
          <div id="menu">
            <div>
              <div className="headerSuperior sp-dark">
                <span className="telefone"><i className="fas fa-phone"></i>(51) 3041-0131</span>
                <span className="telefone"><a href="https://api.whatsapp.com/send?phone=555130410115" aria-labelledby="whatsapp" rel="noopener noreferrer">
                  <i className="fab fa-whatsapp"></i> (51) 3041-0115
                </a></span>
                <span className="chat" onClick={() => {
                  window.tawkToPopup();
                }}><strong>CHAT FALE CONOSCO</strong></span>
              </div>
              <Navbar color="light" light expand="md">
                <NavbarBrand href="/">
                  <Img fixed={data.logo.childImageSharp.fixed} alt="Softplus Informática LTDA" />
                </NavbarBrand>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                  <Nav className="ml-auto" navbar>
                    <NavItem active>
                      <NavLink href="#home" onClick={this.animateScrollTo}>Home</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="#solucoes" onClick={this.animateScrollTo}>Soluções</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="#destaques" onClick={this.animateScrollTo}>Destaques</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="#sobre" onClick={this.animateScrollTo}>Sobre</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="#areacliente" onClick={this.animateScrollTo}>Área do Cliente</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="#contato" onClick={this.animateScrollTo}>Contato</NavLink>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Navbar>
            </div>

          </div >
        );
      }}
    />;
  }
}